<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 px-6 pt-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 sm8 md12 lg2 pb-2 class="nsbold text-left"
              ><span style="font-size: 20px"
                >Hostel Allotment List</span
              >
            </v-flex>
               <v-flex xs12 sm4 md3 lg2 pb-2 class="nsbold text-left"
              >
              <v-btn 
                color="#766BC0"
                class="rounded-xl white--text hidden-xs-only"
                title="Map Student"
                 @click="$router.push({ path: '/roomStrctr' })"
                >Room Management</v-btn
              >
               <v-btn 
               block
                color="#766BC0"
                class="rounded-xl white--text hidden-sm-and-up"
                title="Map Student"
                 @click="$router.push({ path: '/roomStrctr' })"
                >Room Management</v-btn
              >
              </v-flex>
  <v-flex xs12 sm3 md2 lg2 pb-2 class="nsbold text-left"
              >
              <v-btn 
                color="#766BC0"
                class="rounded-xl white--text hidden-xs-only"
                title="Map Student"
                 @click="$router.push({ path: '/outpassStudentList' })"
                >Add Student</v-btn
              >
               <v-btn 
               block
                color="#766BC0"
                class="rounded-xl white--text hidden-sm-and-up"
                title="Map Student"
                 @click="$router.push({ path: '/outpassStudentList' })"
                >Map Student</v-btn
              >
              </v-flex>
            <v-flex xs12 sm3 md2 lg2 pb-2 pl-sm-2 pl-lg-0>
              <v-text-field
                v-model="keyword"
                class="rounded-xl"
                dense
                hide-details
                label="Search : Ad.no/Name"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 px-1 pb-2       
              align-self-center
              class="text-left nsregular"
            >
              <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                clearable
                item-text="hostelName"
                item-value="_id"
                class="rounded-xl"
                hide-details
                outlined
                @input="getData()"
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md3 lg2 pl-lg-4 pb-2 pl-sm-2 text-right>
             <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold rounded-xl white--text hidden-xs-only"
            
            title="Remove student from mapped list"
            @click="approvedialoge=true"
            >Remove Student</v-btn
          >
           <v-btn
           block
            color="#766BC0"
            class="body-2 font-weight-bold rounded-xl white--text hidden-sm-and-up"
            
            title="Remove student from mapped list"
            @click="approvedialoge=true"
            >Remove Student</v-btn
          >
          </v-flex>
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="StData.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <!-- <th class="text-left">Select</th> -->
                      <th class="text-left">No.</th>
                      <th class="text-left">Admission No</th>
                      <th class="text-left" style="width: 20%;">Name</th>
                      <th class="text-left" style="width: 57%;">Hostel</th>
                       <th class="text-left"><v-checkbox
                    v-model="isSelectAll"
                    hide-details
                    class="mt-0"
                    label="Select All"
                    @click="markAll()"
                  ></v-checkbox>
                  <!-- <v-btn
            color="#766BC0"
            x-small
            class="body-2 font-weight-bold rounded-xl"
            outlined
            @click="unMapSt()"
            >Approve</v-btn
          > -->
                  </th>
                      <!-- <th class="text-left">Approve</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in StData" :key="i">
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        {{ item.admissionNo }}
                      </td>
                      <td>
                        {{ item.name }}
                        <!-- </router-link> -->
                      </td>
                      <td>
                        <span v-if="item.mainHostelId">
                          {{ item.mainHostelId.hostelName }}
                        </span><span v-if="item.hostelBlock">
                         ({{ item.hostelBlock.Block }})
                        </span>
                        <span v-else>NA</span>
                      </td>
                       <td>
                            <v-checkbox
                              @click="selectThis(item.checkbox, item._id)"
                              v-model="item.checkbox"
                            ></v-checkbox>
                          </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12>
               <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    
    <v-dialog width="400px" v-model="approvedialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to unmap these students?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="approvedialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click="unMapvalidate()"
            >Approve</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
   <v-snackbar
          :timeout="2000"
          :value="true"
          centered
          
          color="red"
          v-model="showsnackbar"
        > <v-layout align-center pr-4 wrap>
            <v-flex xs12>
              <span style="font-size: 20px"> {{ msg }}</span>
            </v-flex>
          </v-layout>
        </v-snackbar>
    </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      keyword: null,
      ServerError: false,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      hostelName: null,
      hostel: null,
      HostelList: [],
 isSelectAll: false,
      selected: [],
      checkdialoge: false,
      approvedialoge: false,
      StData:[],
    };
  },
  mounted() {
    this.gethostel();
    this.getData();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
hostelName() {
    //   console.log("received hostelname");
      this.getData();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
    // cvalue() {
    //   if(this.page>1)
    //   this.page=1
    //   this.getData();
    // },
  },
  methods: {
     markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.StData.length; i++) {
          this.StData[i].checkbox = true;
          this.selected.push(this.StData[i]._id);
        }
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.StData.length; i++) {
          this.StData[i].checkbox = false;
          this.selected = [];
        }
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id) {
      this.isSelectAll = false;
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
        }
      } else {
        var ar = this.selected;
        this.selected = ar.filter((x) => x != id);
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/roommap/hostelwise/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
        hostelId: this.hostel,
          page: this.page,
          limit: 20,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.StData = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
     gethostel() {
      axios({
        method: "get",
        url: "/main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    unMapvalidate() {
        if(this.selected.length<=0) {
            this.msg="Please select atleast one student"
         this.showsnackbar = true;
            return
        }
        else{
          this.unMapSt();
        }
    },
     unMapSt() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/removemap/multiplestudent/hostel",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
         studentid: this.selected,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.selected=[]
            this.approvedialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.StData = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
